import {
  FaExclamationTriangle
} from 'react-icons/fa'

import {
  VscStarFull
} from 'react-icons/vsc'

import {
  GiCutDiamond
} from 'react-icons/gi'

export {
  FaExclamationTriangle as ExclamationTriangle,
  VscStarFull as Star,
  GiCutDiamond as Gem
}
